@import "./mixins";
@import "./loops";
@font-face {
  font-family: "iransans";
  src: url("./../fonts/IRANYekanRegularFaNum.ttf");
}
.fa {
  font-family: iransans;
}
.en {
  font-family:Verdana;
}
html {
  scroll-behavior: smooth;
  user-select: none;
}
body {
  background-color: #282c34;
  color: white;
  @include scrollbar(0, #5c6069);
  scroll-behavior: smooth;
}
pre {
  background-color: #ffffff10;
  padding: 8px 16px;
  border-radius: 8px;
  direction: ltr;
  text-align: left;
  overflow-x: auto;
  line-height: 2;
}
.page {
  margin: 8%;
  margin-top: 300px;
  margin-bottom: 20px;
}
p {
  padding: 0;
  margin: 0;
}

a:-webkit-any-link {
  color: inherit;
}
button,
a {
  text-decoration: none;
  &:focus {
    outline: none;
  }
}
.hr {
  border: 1px solid #ededed;
}
.grid {
  display: grid;
}
.flex {
  display: flex;
  align-items: center;
  &.center {
    justify-content: center;
  }
  &.between {
    justify-content: space-between;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.h-full {
    height: 100vh;
  }
  &.w-full {
    width: 100% !important;
  }
  .flex-1 {
    flex: 1 !important;
  }
  &.row {
    flex-direction: row;
  }
  &.col {
    flex-direction: column;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  @for $space-base from 1 through 100 {
    &.h-#{$space-base} {
      height: $space-base + vh !important;
    }
  }
}
.hidden {
  display: none !important;
}
.full-width {
  width: 100%;
}
.text-default {
  color: white;
}
.text-danger {
  color: rgb(124, 8, 8);
}
.text-success {
  color: green;
}
.text-primary {
  color: aqua;
}
.absolute {
  position: absolute;
}
.stiky {
  position: sticky;
  top: 0;
  z-index: 10;
}
.pointer {
  cursor: pointer;
}
.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-success {
  color: green !important;
}
.text-primary {
  color: aqua !important;
}
.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}
.muted {
  opacity: 0.5;
}
.bold {
  font-weight: bold;
}
input[type="search"]:focus,
input[type="search"]:focus-visible {
  outline: none;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
