// ------------------------------------------- اسکرول -------------------------------------
@mixin scrollbar($val, $color: #e4e9ec) {
    /* width */
    &::-webkit-scrollbar {
        width: 4px !important;
        color: #d1d1d1 !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 100px white !important;
        @if $val {
            margin-top: $val + px;
            margin-bottom: $val + px;
        }
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        @if $color {
            background: $color !important;
        } @else {
            background: #e4e9ec !important;
        }
        border-radius: 4px !important;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        @if $color {
            background: $color !important;
        } @else {
            background: #e4e9ec !important;
        }
    }
}
