.navigation {
  position: absolute;
  top: 10%;
  left: 8%;
  gap: 20px;
  z-index: 1;
  flex-wrap: wrap;
  a {
    opacity: 0.8;
    font-size: 1rem;
    transition: all 0.3s;
    text-shadow: 0 0 3px black;
    text-decoration: none;
    &:hover {
      opacity: 1;
      position: relative;
    }
    i {
      margin-right: 4px;
      opacity: 0.6;
    }
    &.about {
      padding: 4px 8px;
      border-radius: 4px;
      background-color: green;
      color: white;
      text-shadow: none;
      opacity: 1;
    }
  }
}
