// ------------------------------------------- مارجین ها و پدینگ ها -------------------------------------
@for $space-base from 0 through 200 {
  .m-#{$space-base} {
    margin: $space-base + px !important;
  }
  .mlr-#{$space-base} {
    margin-left: $space-base + px !important;
    margin-right: $space-base + px !important;
  }
  .mtb-#{$space-base} {
    margin-top: $space-base + px !important;
    margin-bottom: $space-base + px !important;
  }
  .mr-#{$space-base} {
    margin-right: $space-base + px !important;
  }
  .ml-#{$space-base} {
    margin-left: $space-base + px !important;
  }
  .mt-#{$space-base} {
    margin-top: $space-base + px !important;
  }
  .mt-un-#{$space-base} {
    margin-top: -$space-base + px !important;
  }
  .mb-#{$space-base} {
    margin-bottom: $space-base + px !important;
  }
  .p-#{$space-base} {
    padding: $space-base + px !important;
  }
  .plr-#{$space-base} {
    padding-left: $space-base + px !important;
    padding-right: $space-base + px !important;
  }
  .ptb-#{$space-base} {
    padding-top: $space-base + px !important;
    padding-bottom: $space-base + px !important;
  }
  .pr-#{$space-base} {
    padding-right: $space-base + px !important;
  }
  .pl-#{$space-base} {
    padding-left: $space-base + px !important;
  }
  .pt-#{$space-base} {
    padding-top: $space-base + px !important;
  }
  .pb-#{$space-base} {
    padding-bottom: $space-base + px !important;
  }
  .w-#{$space-base} {
    width: $space-base + px !important;
  }
  .h-#{$space-base} {
    height: $space-base + px !important;
  }
  .min-w-#{$space-base} {
    min-width: $space-base + px !important;
  }
  .max-w-#{$space-base} {
    min-width: $space-base + px !important;
  }
  .br-#{$space-base} {
    border-radius: $space-base + px !important;
  }
  .fs-#{$space-base} {
    font-size: $space-base + px !important;
  }
}

@for $space-base from 1 through 50 {
  .gap-#{$space-base} {
    gap: $space-base + px !important;
  }
}
@for $space-base from 0 through 10 {
  .o-#{$space-base} {
    opacity: $space-base/10 !important;
  }
}
@for $space-base from -1 through 10 {
  .z-index-#{$space-base} {
    z-index: $space-base !important;
  }
}
